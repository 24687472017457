<template lang="pug">
head-meta(title-page="Подтверждение почты" )
the-verify-email
contacts-page
</template>

<script>
import TheVerifyEmail from '../components/login/TheVerifyEmail'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsPage from './ContactsPage'

export default {
  name: 'VerifyEmailPage',
  components: { ContactsPage, HeadMeta, TheVerifyEmail }
}
</script>
