<template lang="pug">
article.login
  .login__cont.cont
    template(v-if="status === 'progress'")
      h1.login__title.title Проверяем почту...
    template(v-else-if="status === 'error'")
      h1.login__title.title Ошибка подтверждения
      p.login__text.text-big Пользователь не найден, либо истек срок действия ссылки
    template(v-else-if="status === 'success'")
      h1.login__title.title Спасибо!
      p.login__text.text-big Почта подтверждена, теперь можете войти в аккаунт
      router-link.login__submit.button-white(:to="{ name: 'Login' }") Войти
</template>

<script>
import '@/assets/styles/components/login.sass'
import { verifyEmail } from '../../assets/scripts/API'

export default {
  name: 'TheVerifyEmail',
  data () {
    return {
      status: 'progress',
      token: null
    }
  },
  methods: {
    checkToken () {
      this.token = this.$route.query.token
      if (!this.token) {
        this.status = 'error'
      } else {
        verifyEmail(this.token).then(data => {
          this.status = data.status === 'error' ? 'error' : 'success'
        })
      }
    }
  },
  mounted () {
    this.checkToken()
  }
}
</script>
