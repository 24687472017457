<template lang="pug">
teleport(to="body")
  component(:is="'script'", type="application/ld+json", v-html="jsonld" )
</template>

<script>
import { phone, mail, url } from '../../assets/scripts/variables'

export default {
  name: 'SchemaOrgContacts',
  props: {
    product: Object
  },
  data () {
    return {
      phone,
      mail,
      url
    }
  },
  computed: {
    jsonld () {
      return JSON.stringify({
        '@context': 'http://www.schema.org',
        '@type': 'Organization',
        name: 'Музей «Шоколадный Выборг»',
        url: this.url,
        logo: `${this.url}/images/logo.svg`,
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'ул. Штурма, Пороховой погреб, литера А',
          addressLocality: 'г. Выборг',
          addressRegion: 'Ленинградская область',
          postalCode: '188800',
          addressCountry: 'Российская Федерация'
        },
        email: this.mail.mask,
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: this.phone.mask
        }
      })
    }
  }
}
</script>
